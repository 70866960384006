import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { sharedGuards, sharedRoutes } from '@shared/routes'
import { Role } from '@super-admin-app/src/utils/roles/index'

const moduleRoutes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@super-admin-app/src/views/auth/Login.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@super-admin-app/src/views/auth/ForgotPassword.vue')
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@super-admin-app/src/views/auth/ResetPassword.vue')
  },
  {
    path: '/admins-list',
    name: 'admins-list',
    component: () => import('@super-admin-app/src/views/adminsList/AdminsList.vue'),
    meta: {
      middleware: [Role.Admin]
    }
  },
  {
    path: '/hosts-list',
    name: 'hosts-list',
    component: () => import('@super-admin-app/src/views/hosts/HostsList.vue'),
    meta: {
      middleware: [Role.Admin]
    }
  },
  {
    path: '/host/:id',
    name: 'host-page',
    component: () => import('@super-admin-app/src/views/hosts/OneHostPage.vue'),
    meta: {
      middleware: [Role.Admin]
    }
  },
  {
    path: '/events-categories-list',
    name: 'events-categories-page',
    component: () => import('@super-admin-app/src/views/events/categories/EventsCategoriesList.vue'),
    meta: {
      middleware: [Role.Admin]
    }
  },
  {
    path: '/events-list',
    name: 'events-page',
    component: () => import('@super-admin-app/src/views/events/list/EventsList.vue'),
    meta: {
      middleware: [Role.Admin]
    }
  },
  {
    path: '/event/:id',
    name: 'event-page',
    component: () => import('@super-admin-app/src/views/events/UpdateEventPage.vue'),
    meta: {
      middleware: [Role.Admin]
    }
  },
  {
    path: '/create/event',
    name: 'create-event-page',
    component: () => import('@super-admin-app/src/views/events/CreateEventPage.vue'),
    meta: {
      middleware: [Role.Admin]
    }
  },
  {
    path: '/attendees-list',
    name: 'attendees-page',
    component: () => import('@super-admin-app/src/views/attendees/pages/AttendeesList.vue'),
    meta: {
      middleware: [Role.Admin]
    }
  },
  {
    path: '/settings',
    name: 'settings-page',
    component: () => import('@super-admin-app/src/views/settings/pages/SettingsPage.vue'),
    meta: {
      middleware: [Role.Admin]
    }
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: () => import('@super-admin-app/src/views/pricing/pages/Transactions.vue'),
    meta: {
      middleware: [Role.Admin]
    }
  },
  {
    path: '/test-page',
    name: 'test',
    component: () => import('@super-admin-app/src/views/Test.vue'),
    meta: {
      middleware: [Role.Admin]
    },
  },
  {
    path: '/businesses-list',
    name: 'businesses-list',
    component: () => import('@super-admin-app/src/views/businesses/pages/BusinessesList.vue'),
    meta: {
      middleware: [Role.Admin]
    },
  },
  {
    path: '/business/:id',
    name: 'business-page',
    component: () => import('@super-admin-app/src/views/businesses/pages/OneBusinessPage.vue'),
    meta: {
      middleware: [Role.Admin]
    }
  },
  {
    path: '/ads-list',
    name: 'ads-list',
    component: () => import('@super-admin-app/src/views/businesses/pages/ads/AdsList.vue'),
    meta: {
      middleware: [Role.Admin]
    },
  },
  {
    path: '/create/ad',
    name: 'create-ad-page',
    component: () => import('@super-admin-app/src/views/businesses/pages/ads/CreateAdPage.vue'),
    meta: {
      middleware: [Role.Admin]
    }
  },
  {
    path: '/ad/:id',
    name: 'ad-page',
    component: () => import('@super-admin-app/src/views/businesses/pages/ads/UpdateAdPage.vue'),
    meta: {
      middleware: [Role.Admin]
    }
  },
  ...sharedRoutes
]

const router = createRouter({
  routes: moduleRoutes,
  history: createWebHistory(process.env.BASE_URL)
})

router.beforeEach(sharedGuards)

export default router
